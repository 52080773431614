import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, Observable, from  } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { LanguageService } from './language.service';
import { formatDate } from '@angular/common';
import { Plugins } from '@capacitor/core';
import { AlertController, ToastController } from '@ionic/angular';
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { NavController } from "@ionic/angular";
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  language;

  constructor(
    private alertCtrl: AlertController,
    private http: HttpClient,
    private languageService: LanguageService,
    private navCtrl:NavController,
    private toastController: ToastController,
    private authService:AuthService
  ) { 

    this.language = this.languageService.language();

  }


  getBannerHome(){
    return this.http.get<any>(
      `${environment.host}/slideShows?type=slider`
    );
  }
  getSettingsContent(content){
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Localization': `${this.language}`
      })
    };

    return this.http.get<any>(
      `${environment.host}/contents?key=${content}`
    );
  }

  getFeaturedHome(){
    return this.http.get<any>(
      `${environment.host}/slideShows?type=featured`
    );
  }

  getCategoryList() {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Localization': `en`
      })
    };

    return this.http.get<any>(
      `${environment.host}/categories`, httpOptions
    );

  }

  getInterestList(){

    return this.http.get<any>(
      `${environment.host}/interests`
    );

  }

  getPaymentChannel() {
    
    return this.http.get<any>(
      `${environment.host}/paymentChannels`
    );

  }

  getPersonalEventContent() {
    
    return this.http.get<any>(
      `${environment.baseUrl}/api/ajukan_sekarang`
    );

  }

  getPersonalEventList() {
    
    return this.http.get<any>(
      `${environment.baseUrl}/api/event_ajukan_sekarang`
    );

  }

  getPersonalEventPaymentList() {
    
    return this.http.get<any>(
      `${environment.baseUrl}/api/payment_ajukan_sekarang`
    );

  }

  getWeekendIncrement(day){

    let increment = 0;

    if(day == "Monday"){

      increment = 5

    }

    if(day == "Tuesday"){

      increment = 4

    }

    if(day == "Wednesday"){

      increment = 3

    }

    if(day == "Thursday"){

      increment = 2

    }

    if(day == "Friday"){

      increment = 1

    }

    if(day == "Saturday"){

      increment = 0

    }

    if(day == "Sunday"){

      increment = -1

    }

    return increment;

  }

  getDayMonthFormat(fulldate){

    return formatDate(new Date(fulldate), 'yyyy-MM-dd', 'en-US');

  }

  setRouterHistory(route){

    from(Plugins.Storage.get({key: 'historyrouter'})).subscribe(

      res => {

        if(res.value){

          let tmpArray = JSON.parse(res.value)

          if(tmpArray[tmpArray.length - 1] != route){

            if(tmpArray.length > 5){

              tmpArray.splice(0, 1)
              tmpArray.push(route)
  
            }else{
  
              tmpArray.push(route)
  
            }

          }

          console.log(tmpArray)

          Plugins.Storage.set({key: 'historyrouter', value: JSON.stringify(tmpArray)});

        }else{

          let tmpArray = []

          tmpArray.push(route)

          Plugins.Storage.set({key: 'historyrouter', value: JSON.stringify(tmpArray)});

        }

      },
      err => {

        console.log(err)

      }

    )

  }

  resetHistoryRoute(){
  
    let tmpArray = []

    console.log(tmpArray)

    Plugins.Storage.set({key: 'historyrouter', value: JSON.stringify(tmpArray)});
  
  }

  showAlert(message) {
    this.alertCtrl.create({
      mode: 'ios',
      cssClass: 'custom-alert',
      message: message,
      buttons: ['OK']
    })
      .then(alert => {
        alert.present();
      });
  }

  scanQR2(token,code){
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`
      })
    };
    let body = new FormData();
    body.append('tenant_url',code);
    return this.http.post<any>(
      `${environment.host}/checkin`,
      body,httpOptions
    );

  }

  scanQR(code){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('tenant_url',code);
        return this.http.post<any>(
          `${environment.host}/checkin`,
          body,httpOptions
        );
      })
    );
  }
  getTenantDetail(code){
    return this.authService.token.pipe(
      take(1),
      switchMap(token => {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };
        let body = new FormData();
        body.append('code',code);
        return this.http.post<any>(
          `${environment.host}/tenant`,
          body,httpOptions
        );
      })
    );
  }
  getHistoryRoute(){

    return from(Plugins.Storage.get({key: 'historyrouter'})).pipe(
      map(historyrouter => {
        
        if(historyrouter){

          return JSON.parse(historyrouter.value)

        }else{

          return []

        }

      })
    );

  }
  openPrivateMessage(data){
    let navigationExtras: NavigationExtras = {
        queryParams: {uts:btoa(JSON.stringify(data))}
    };
    this.navCtrl.navigateForward(['/home/messanger'], navigationExtras);
  }
 

  getDayMonthForamt(fulldate){
    return formatDate(new Date(fulldate), 'yyyy-MM-dd', 'en-US')
  }

  getErrMessage(err){
    return err.error.message
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000
    });
    toast.present();
  }

}
