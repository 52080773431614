import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map, take, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from } from 'rxjs';
import { User } from '../models/user.model';
import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';
import { Token } from '../models/token.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _user = new BehaviorSubject<User>(null);
  private _token = new BehaviorSubject<Token>(null);
  private _fcm = new BehaviorSubject<String>(null);
  language;

  constructor(
    private http: HttpClient,
    private languageService: LanguageService
  )
  {
    this.language = this.languageService.language();
  }

  get userIsAuthenticated() {

    return from(Plugins.Storage.get({key: 'tokenData'})).pipe(
      map(tokenData => {
        if(!tokenData || !tokenData.value){
          return false;
        }else{
          let token = new Token().deserialize(JSON.parse(tokenData.value))

          return !token.isTokenExpired()

        }
      })
    );

  }

  get token() {

    return from(Plugins.Storage.get({key: 'tokenData'})).pipe(
      map(tokenData => {
        if(!tokenData || !tokenData.value){
          return null;
        }
        
        let token = new Token().deserialize(JSON.parse(tokenData.value))

        return token.getAccessToken();
      })
    );

    // return this._token.asObservable().pipe(
    //   map(token => {
    //     if(token) {
    //       return token.access_token;
    //     } else {
    //       return null;
    //     }
    //   })
    // );
  }

  get user(){
    // var dataUser =  this._user.value;
    // if(dataUser){
    //   localStorage.dataGlobal =  JSON.stringify(dataUser);
    // }
    // return this._user.asObservable();

    return from(Plugins.Storage.get({key: 'authData'})).pipe(
      map(authData => {
        if(!authData || !authData.value){
          return null;
        }

        return JSON.parse(authData.value);
      })
    );

  }

  loginCode(code){
    let body = new FormData();
    body.append('code',code);

    return this.http.post<any>(
      `${environment.host}/auth/invitation`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      // tap(this.setUserData.bind(this))
    );
  }

  login(email: string, password: string, fcm_token: any = '', uuid: string, os: string, os_version: string, model: string,code:any){
    let body = new FormData();
    body.append('email',email);
    body.append('password',password);
    if(fcm_token != '') {
      body.append('fcm_token',fcm_token);
    }
    body.append('uuid',uuid);
    body.append('os',os);
    body.append('os_version',os_version);
    body.append('code',code);
    body.append('model',model);
    body.append('language',this.language);

    return this.http.post<any>(
      `${environment.host}/login`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      // tap(this.setUserData.bind(this))
    );
  }
  
  
  loginVIP(qrcode: string){
    let body = new FormData();
    body.append('qrcode',qrcode);
  
    return this.http.post<any>(
      `${environment.baseUrl}/api/event/scan_invitation_code`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      // tap(this.setUserData.bind(this))
    );
  }

  loginGoogle(token: string, fcm_token: any = '',  uuid: string, os: string, os_version: string, model: string){
    let body = new FormData();
    body.append('token',token);
    if(fcm_token != '') {
      body.append('fcm_token',fcm_token);
    }
    body.append('uuid',uuid);
    body.append('os',os);
    body.append('os_version',os_version);
    body.append('model',model);
    body.append('language',this.language);

    return this.http.post<any>(
      `${environment.host}/auth/login/google`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      //tap(this.setUserData.bind(this))
    );
  }

  appleLogin(email: string, fullname: string,  apple_id: string, hash_code: string, fcm_token: any = '',  uuid: string, os: string, os_version: string, model: string) {
    let body = new FormData();
    if(email != '' && email != 'null' && email != null) {
      body.append('member_email',email);
    }
    if(email != '' && email != 'null' && email != null) {
      body.append('member_fullname',fullname);
    }
    body.append('apple_id',apple_id);
    body.append('hash_code',hash_code);
    if(fcm_token != '') {
      body.append('fcm_token',fcm_token);
    }
    body.append('uuid',uuid);
    body.append('os',os);
    body.append('os_version',os_version);
    body.append('model',model);
    body.append('language',this.language);

    return this.http.post<any>(
      `${environment.baseUrl}/api/applesignin`,
      body
    )
    .pipe(
      map(resData => {
        return resData;
      }),
      //tap(this.setUserData.bind(this))
    );
  }

  setUserData(data: any) {
    console.log(data);
    const user = new User(
      data.id,
      data.name,
      data.email,
      data.phone,
      data.company_name,
      data.company_position,
      data.photo_url,
      data.interests
    );

    this._user.next(user);

    const authData = JSON.stringify({
      id: data.id,
      name: data.name,
      email: data.email,
      phone: data.phone,
      company_name: data.company_name,
      company_position: data.company_position,
      photo_url: data.photo_url,
      interests: data.interests
    });

    Plugins.Storage.set({key: 'authData', value: authData});
  }

  autoLogin() {
    return from(Plugins.Storage.get({key: 'authData'})).pipe(
      map(storedData => {
        if(!storedData || !storedData.value){
          return null;
        }
        const parsedData = JSON.parse(storedData.value) as {
          id: string,
          name: string,
          email: string,
          phone: string,
          company_name: string,
          company_position: string,
          photo_url: string,
          interests: string
        };

        const user = new User(
          parsedData.id,
          parsedData.name,
          parsedData.email,
          parsedData.phone,
          parsedData.company_name,
          parsedData.company_position,
          parsedData.photo_url,
          parsedData.interests
        );
        return user;
      }),
      tap(user => {
        if(user){
          this._user.next(user);
        }
      }),
      map(user => {
        return !!user;
      })
    );
  }

  get fcm(){
    return this._fcm.asObservable();
  }

  setFcmToken(token){
    this._fcm.next(token);
  }

  getTenantKey() {
    return from(Plugins.Storage.get({key: 'tenant'})).pipe(
      map(data => {
         return data;
      })
    );
  }
  removeTenantKey(){
    Plugins.Storage.remove({key: 'tenant'});
  }
  setTenantKey(tenant) {
    return Plugins.Storage.set({key: 'tenant', value: tenant});
  }


  setUserToken(data: any) {

    const authData = JSON.stringify({
      access_token: data.access_token,
      token_type: data.token_type,
      expires_in: data.expires_in,
      setDate: Math.floor(Date.now() / 1000)
    });

    Plugins.Storage.set({key: 'tokenData', value: authData});
  }

  refreshToken(){

    return this.token.pipe(
      take(1),
      switchMap(token => {
        
        console.log(token)
        
        let body = new FormData();

        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': `Bearer ${token}`
          })
        };

        console.log(httpOptions)

        return this.http.post<any>(
          `${environment.host}/auth/refresh`, body ,
          httpOptions
        );
      })
    );

  }

}
