import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { take, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(){
    return this.authService.userIsAuthenticated.pipe(
      take(1),
      switchMap(isAuthenticated => {

        return of(isAuthenticated);

      }),
      tap(isAuthenticated => {

        if(!isAuthenticated){
          this.router.navigateByUrl('/login');
        }
      })
    );

  }
}
