export interface MyEvent {
	event_id: number,
	event_name: string,
	event_image: string,
	event_location: string,
	event_start_date: string,
	event_end_date: string
}

export class User {
	public constructor(
		public id: string,
		public name: string,
		public email: string,
		public phone: string,
		public company_name: string,
		public company_position: string,
		public photo_url: string,
		public interests: string
	) {}
}

export class Connections {
	public constructor(
		public id: string,
		public name: string,
		public email: string,
		public phone: string,
		public company_name: string,
		public company_position: string,
		public photo_url: string,
		public interests: string
	) {}
}
