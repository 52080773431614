import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private api: ApiService) {}

  canActivate() {
    if (this.api.isLoggedIn()) {
      return true;
    }

    this.router.navigate(["/login"]);
    return false;
  }
}

@Injectable({
  providedIn: "root",
})
export class RedirectIfAuthGuard implements CanActivate {
  constructor(private router: Router, private api: ApiService) {}
  canActivate() {
    if (this.api.isLoggedIn()) {
      this.router.navigate(["/home"]);
      return false;
    }
    return true;
  }
}
