import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { from, throwError } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import * as moment from "moment";
import { Plugins } from '@capacitor/core';
import { Token } from '../models/token.model';
interface authResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getAuthorizationToken() {
    return from(Plugins.Storage.get({key: 'tokenData'})).pipe(
      map(tokenData => {
        if(!tokenData || !tokenData.value){
          return null;
        }
        
        let token = new Token().deserialize(JSON.parse(tokenData.value))

        return token.getAccessToken();
      })
    );
    
    // console.log(window.localStorage.getItem('tokenData'));
    // return window.localStorage.getItem("access_token");
  }

  getLocale() {
    return window.localStorage.getItem("locale") || "en";
  }

  setLocale(locale: string) {
    return window.localStorage.setItem("locale", locale);
  }

  setUser(response: any) {
    return window.localStorage.setItem("user", JSON.stringify(response.data));
  }

  /**
   * Handle Login
   * @param email
   * @param password
   */
  login(email: string, password: string) {
    return this.http.post("auth/login", { email, password }).pipe(
      tap((data: authResponse) => {
        this.setSession(data);
      }),
      tap(() => {
        this.getUser();
      }),
      catchError(this.handleError)
    );
  }

  loginSocial(provider: string, token: string) {
    return this.http
      .post(`auth/social/${provider}`, { token })
      .pipe(catchError(this.handleError));
  }

  logout(callbackSuccess?: any, callbackError?: any) {
    return this.http
      .delete("auth/logout")
      .pipe(catchError(this.handleError))
      .subscribe(
        (data) => {
          window.localStorage.removeItem("access_token");
          window.localStorage.removeItem("expires_at");
          window.localStorage.removeItem("locale");
          window.localStorage.removeItem("user");
          callbackSuccess && callbackSuccess(data);
        },
        (error) => {
          callbackError && callbackError(error);
        }
      );
  }

  forgotPassword(email: string) {
    const deep_link = "http://kelolainapi/v4/reset-password";
    return this.http
      .post("auth/password", { email, deep_link })
      .pipe(catchError(this.handleError));
  }

  resetPassword(params: any) {
    return this.http
      .put("auth/password", params)
      .pipe(catchError(this.handleError));
  }

  register(params: any) {
    return this.http
      .post("auth/register", params)
      .pipe(catchError(this.handleError));
  }

  getUser() {
    return this.http.get("user").subscribe(this.setUser);
  }

  getInterested() {
    return this.http.get("interested");
  }

  setSession(data: authResponse) {
    const expiresAt = moment().add(data.expires_in, "second");

    window.localStorage.setItem("access_token", data.access_token);
    window.localStorage.setItem(
      "expires_at",
      JSON.stringify(expiresAt.valueOf())
    );
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  private getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  /**
   * Handle Http Error
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    const errorData = {
      status: error.status,
      message: "Something bad happened, please try again later.",
      errors: null,
    };

    if (error.error instanceof ErrorEvent) {
      errorData.message = `An error occurred: ${error.error.message}`;
    } else {
      if (error.status === 422) {
        errorData.message = error.error.message;
        errorData.errors = error.error.errors;
      }
    }

    return throwError(errorData);
  }
}
