import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { RedirectIfAuthGuard } from "./services/api.guard";
import { AuthGuard } from "./services/auth.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    canActivate: [RedirectIfAuthGuard],
    loadChildren: () =>
      import("./auth/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "login/:code",
    canActivate: [RedirectIfAuthGuard],
    loadChildren: () =>
      import("./auth/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "registration",
    canActivate: [RedirectIfAuthGuard],
    loadChildren: () =>
      import("./auth/registration/registration.module").then(
        (m) => m.RegistrationPageModule
      ),
  },
  {
    path: "forgot-password",
    canActivate: [RedirectIfAuthGuard],
    loadChildren: () =>
      import("./auth/forgot/forgot.module").then((m) => m.ForgotPageModule),
  },
  {
    path: "change-password",
    loadChildren: () =>
      import("./auth/change-password/change-password.module").then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'tenant/display/:code',
   // canActivate: [RedirectIfAuthGuard],
    loadChildren: () => import('./tenant/display/display.module').then( m => m.DisplayPageModule)
  },
  {
    path: 'tenant/display/:code/:automatedNext',
   // canActivate: [RedirectIfAuthGuard],
    loadChildren: () => import('./tenant/display/display.module').then( m => m.DisplayPageModule)
  },
  {
    path: 'scanner',
    canActivate: [RedirectIfAuthGuard],
    loadChildren: () => import('./scanner/scanner.module').then( m => m.ScannerPageModule)
  },
  {
    path: 'register-success',
    loadChildren: () => import('./auth/register-success/register-success.module').then( m => m.RegisterSuccessPageModule)
  },
  {
    path: 'home-tabs',
    loadChildren: () => import('./home-tabs/home-tabs.module').then( m => m.HomeTabsPageModule),
    canActivate: [AuthGuard],
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
