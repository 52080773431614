import { Component } from "@angular/core";

import { AlertController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthService } from "./services/auth.service";
import { filter } from "rxjs/internal/operators/filter";
import { NavigationEnd, Router } from "@angular/router";
import { GeneralService } from "./services/general.service";

import firebase from 'firebase/app'
import 'firebase/database';
import { environment } from 'src/environments/environment';
import { FCMPushRedirect } from "./models/FCMModel/pushredirect.model";


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private router: Router,
    private generalService: GeneralService,
    private alertCtrl: AlertController
  ) {
    this.initializeApp();

    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      console.log('prev:', event.url);

      this.generalService.setRouterHistory(event.url)

    });

    this.listenForceRedirectNotif()


    

  }

  listenForceRedirectNotif(){


  }

  async showAlert(message: string, data:FCMPushRedirect) {
    
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: '',
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Okay',
          handler: () => {
            
            this.router.navigateByUrl("event/"+data.getEventSlug()+"/plenary-hall/session/"+data.getSessionID())

          }
        }
      ]
    });

    await alert.present();
    
    // this.alertCtrl
    //   .create({
    //     mode: "ios",
    //     cssClass: "custom-alert",
    //     message: message,
    //     buttons: ["OK"],
    //   })
    //   .then((alertEl) => alertEl.present());
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

  

  }

}
