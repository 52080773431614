import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApiInterceptor } from "./services/api.interceptor";
import { AuthGuard, RedirectIfAuthGuard } from "./services/api.guard";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
//import * as firebase from 'firebase'
import firebase from "firebase/app";
import "firebase/database"; // If using Firebase database
import "firebase/storage";
import "firebase/auth";
import { environment } from "src/environments/environment";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileTransfer } from "@ionic-native/file-transfer/ngx";
import { RecaptchaModule } from "ng-recaptcha";
import { ReversePipe } from './reverse.pipe';


@NgModule({
  declarations: [AppComponent, ReversePipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    FormsModule,
    RecaptchaModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthGuard,
    RedirectIfAuthGuard,
    FileTransfer
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
